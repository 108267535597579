import marg from './assets/marg.png';
import chris from './assets/chris.png';
import matt from './assets/matt.png';
import nadia from './assets/nadia.png';
import graeme from './assets/graeme.png';
import trina from './assets/trina.png';

export const profiles = [
    {
        firstName: 'Graeme',
        lastName: 'Bunton',
        image: graeme,
        alt: 'Photo of Graeme at Laufas Church.',
        bio: 'For over 20 years Graeme Bunton has been striving to make the world a better place through technology and the Internet.  Graeme was a founding member of the Citizen Lab, one of the world’s foremost centers for research into internet censorship and surveillance.  Since then he’s worked with startups, in eHealth for the Ontario Government, and for Internet infrastructure company Tucows.  He has played a key role in numerous efforts to address global internet governance issues, including managing the impact of the GDPR on the DNS, the transition of the IANA functions, and protecting registrant privacy. He served as elected chair of the ICANN Registrar Stakeholder Group for four years, leading it through some of ICANN’s most contentious and difficult times. He is currently the inaugural director of the DNS Abuse Institute, working to reduce online harms.',
        socials: {
        twitter: 'https://twitter.com/graemebunton',
        instagram: 'https://www.instagram.com/instagraemebunton/',
        website: 'https://graemebunton.com/',
        other: '',
        }
        },
        {
        firstName: 'Trina',
        lastName: 'Hogg',
        image: trina,
        alt: 'Photo of Trina at Laufas Church.',
        bio: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        socials: {
        twitter: '',
        instagram: '',
        website: '',
        other: '',
        }
        },
        {
        firstName: 'Matt',
        lastName: 'Lemche',
        image: matt,
        alt: 'Photo of Matt at Laufas Church.',
        bio:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        socials: {
        twitter: 'https://twitter.com/MattLemche',
        instagram: '',
        website: 'https://mattlemche.com/',
        other: '',
        }
        },
        {
        firstName: 'Nadia',
        lastName: 'Nascimento',
        image: nadia,
        alt: 'Photo of Nadia at Laufas Church.',
        bio: 'Nadia Nascimento is a global marketer, cultural innovator and experience designer whose work lies at the intersection of media, art and activism. Her strategies in digital and experiential have focused on top-tier brands and impact-based ventures from (RED) to VIACOM, the BBC to the United Nations Foundations. Her background in tech, strategy, and culture has put her at the cutting edge of experience design, designing key moments at events like TED, Sundance and Summit Series. She is the Co-Founder of ARTNOIR, a creative organization offering an alternative to the traditional art world narrative promoting bold acts of creative storytelling for communities of opportunity + artists of colour.',
        socials: {
        twitter: 'https://twitter.com/nalei',
        instagram: 'https://www.instagram.com/artnoirco',
        website: 'https://www.artnoir.co',
        other: '',
        }
        },
        {
        firstName: 'Marguerite',
        lastName: 'Moreau',
        image: marg,
        alt: 'Photo of Marguerite at Laufas Church.',
        bio: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        socials: {
        twitter: 'https://twitter.com/MargueriteMorea',
        instagram: 'https://www.instagram.com/margueritemoreau',
        website: '',
        other: '',
        }
        },
        {
        firstName: 'Chris',
        lastName: 'Redman',
        image: chris,
        alt: 'Photo of Chris at Laufas Church.',
        bio: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
        socials: {
        twitter: '',
        instagram: 'https://www.instagram.com/Redcrumbs/',
        website: '',
        other: '',
        }
        },
        
];

export const decadesSupporters = [
'A.J. Bond',
'Adam Strand',
'Alanna Banks',
'Alex M Richmond',
'Allison Villa',
'Alo Maiu',
'Andrew Raponi',
'Angela Heslop',
'Anmatthe',
'Anne Hochberg',
'Armelle Solelhac & SWiTCH Inc.',
'Arun Brahmbhatt',
'Ashleigh Gardner',
'Aubrey Nealon',
'Barry J. Stiefel',
'Ben Jones',
'Beth Coish',
'Bistra',
'Nicole Dalton',
'Brendan Jeffers',
'Brian Kwan',
'Bryant Parson',
'Calvin Lee Reeder',
'Carolyn Cay',
'Casey Margolis',
'Celia Grant',
'Charlotte Dobo',
'Cheryl & Wayne Morishita',
'Christina Bennett',
'Christine Horne',
'chuck bergman',
'Conrad Leach',
'Courtney Yuchtman',
'Dale Cox',
'Dalia Moftah',
'Danny McHugh',
'David Levy',
'David McFadzean',
'drake doremus',
'Elaine Semanik',
'Eli Herron',
'Emily Adams',
'Emma Campbell',
'Eugenie von Tunzelmann',
'Foster Burden c/o Paedea',
'Frank R Cetera',
'Frank Stratton',
'Gabriel Botnick',
'Grace Carter',
'Greg White',
'Griff Foxley',
'Heather Allisonhames',
'Heather Blair',
'Heather Thompson',
'Hilda Rasula',
'Holly Sumner',
'Hugh Dillon',
'Ivan Kalmar',
'James Marlin',
'Jamie Madge',
'Janice Y. Perez',
'Jeff Black',
'jeffrey t brown',
'Jennifer Lemche',
'Jennifer Scott',
'Jeremy Larson',
'Jesse Dybenko',
'JESSE MACHT',
'Jesse Suchmann',
'Joe Matoske',
'Joe Mei',
'John Cash',
'Joni McKervey',
'Jovanna Scorsone',
'Judy and Mike Lemche',
'Julie Janish',
'Justin Shubert',
'Kabira Stokes',
'karen Hughes',
'Kassem Gharaibeh',
'Kat & George Anderson',
'Kate Mucherino in mem. Of Mom Pat Hustek',
'Kate Willoughby',
'Keiron Waites',
'Ken Stern',
'Kenzie Ryder',
'Kerrie Keane',
'Kerry Shaw',
'Kishwar Iqbal',
'Konstadinos Kurelias',
'Krista Ricciattie',
'Kristen Corvers',
'Kristopher Turner',
'Kristy Clarke',
'Laura Ouellette',
'Laura Raymond',
'Lauren Black',
'Lena Georgas',
'Lindy booth',
'Lisa Bunton',
'Logan St. Clair',
'Lois Brown',
'Luke Frydenger',
'M. Bailey',
'Mackenzie Korb',
'Martha Preciado R.',
'Mary Anne De Iulis',
'Matt',
'Matt Aaron Krinsky',
'Matt Candeloro',
'Matt Clarke',
'matt lyon',
'Meaghan Denomme',
'Meike',
'Meredith J Powell',
'Michael B Wells',
'Michael LaHood',
'Michelle Furnace',
'Mike Damewood',
'Miss Jo Gifford',
'Mohamed Karim Ben Ali',
'Morty Alman',
'mynameismonkey',
'Name',
'Nathan Maharaj',
'Nell Waters',
'Nicholas Carella',
'Nick Coombe',
'Nina Fehren',
'Norah Murphy',
'Pat Kelly',
'Paul Viafora',
'Paulo Costanzo',
'Phillip Robbins',
'Rachael Kelley',
'Rasmus Slotø',
'Reese Mankenberg',
'Richard and Erika',
'Rob Kelly',
'Robb Webster',
'Robi Vanos',
'Ruain Flanagan',
'Ryan Freeman',
'Samantha Rader',
'Samuel Johnson',
'Sara F',
'Sarah Feeley',
'sarah hardy',
'Sara Fuss',
'Scott Thompson',
'Sean Rinn',
'Simon Worley',
'Slogan6929',
'Sonja Di Giulio',
'sonja rasula',
'Stephen Breen',
'Stephen Dunn',
'steve and anne moreau',
'Tammy Milbury',
'Teddy Sears',
'Todd Mazer',
'Tom De Iulis',
'Tony Haofu',
'Tracy McDonald',
'Valeska Matziol',
'Victoria Sights',
'William Flynn',
'Zandy O Ariss',
]

